import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import {
  timer as rxTimer,
  take as rxTake,
} from 'rxjs';

import { ToastService } from '@messaging/services';
import { LoginService } from '@login/services';
import { PasswordReset } from '@login/types';
import { LoginErrorStateMatcher, Validation, createPasswordStrengthValidator } from '@login/utils';

@Component({
  selector: 'kws-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public readonly headerTitle: string = 'Password Reset';
  public submitted: boolean = false;
  public matcher = new LoginErrorStateMatcher();
  public showStrengthIndicator = false;
  public userFormGroup: FormGroup = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      createPasswordStrengthValidator(),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
    ]),
  }, {
    validators: [Validation.match('password', 'confirmPassword')]
  });

  private resetToken: string = '';

  get userFormControl() {
    return this.userFormGroup.controls;
  }

  get passwordControl() {
    return this.userFormGroup.controls.password;
  }
  get passwordConfirmControl() {
    return this.userFormGroup.controls.confirmPassword;
  }

  constructor(
    private loginService: LoginService,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.checkIfTokenExists();
  }

  private checkIfTokenExists() {
    this.activatedRoute.queryParams.pipe(
      rxTake(1),
    ).subscribe((queryParams) => {
      if (!queryParams.t) {
        this.toastService.queueToastMessage({
          content: 'The token does not exist, please request a password reset again.',
          theme: 'error'
        });
        rxTimer(600).subscribe(() => {
          this.router.navigate(['/request-password']);
        });
      }
      this.resetToken = queryParams.t;
    });
  }

  public resetPassword(): void {
    if (this.userFormGroup.valid) {
      this.submitted = true;
      const postData = this.userFormGroup.value as PasswordReset;
      postData.resetToken = this.resetToken;

      this.loginService.passwordReset(postData).subscribe({
        next: (response) => {
          if (response.IsValid) {
            this.toastService.queueToastMessage({
              content: 'Your password has been successfully changed.',
              theme: 'success'
            });
            rxTimer(600).subscribe(() => {
              this.router.navigate(['/login']);
            });
          } else {
            this.toastService.queueToastMessage({
              content: response.Message != '' ? response.Message : 'Your password was not reset, please request a password reset again.',
              theme: 'error',
              displayTime: 7000,
            });
            this.router.navigate(['/request-password']);
          }
        },
        error: (error) => {
          if (error instanceof HttpResponse || error instanceof HttpErrorResponse) {
            this.toastService.queueToastMessage({
              content: error.statusText,
              theme: 'error'
            });
          } else if (error instanceof Error) {
            this.toastService.queueToastMessage({
              content: error.message,
              theme: 'error'
            });
          } else {
            this.toastService.queueToastMessage({
              content: error,
              theme: 'error'
            });
          }
        },
        complete: () => {
          this.submitted = false;
        }
      });
    }
  }

  public passwordFocus() {
    this.showStrengthIndicator = true;
  }

  public passwordBlur() {
    this.showStrengthIndicator = false;
  }
}
