<div
  class="strength-indicator"
  *ngIf="passwordControl"
>
  <div class="strength-indicator__row">
    <mat-icon class="strength-indicator__row--icon">
      {{ !passwordControl.value || passwordControl.errors?.minlength ? 'check_circle_outline' : 'check_circle' }}
    </mat-icon>
    At least 8 characters
  </div>
  <div class="strength-indicator__row">
    <mat-icon class="strength-indicator__row--icon">
      {{ !passwordControl.value || passwordControl.errors?.upperCase ? 'check_circle_outline' : 'check_circle' }}
    </mat-icon>
    UPPERCASE
  </div>
  <div class="strength-indicator__row">
    <mat-icon class="strength-indicator__row--icon">
      {{ !passwordControl.value || passwordControl.errors?.lowerCase ? 'check_circle_outline' : 'check_circle' }}
    </mat-icon>
    lowercase
  </div>
  <div class="strength-indicator__row">
    <mat-icon class="strength-indicator__row--icon">
      {{ !passwordControl.value || passwordControl.errors?.numbers ? 'check_circle_outline' : 'check_circle' }}
    </mat-icon>
    Numbers (123)
  </div>
  <div class="strength-indicator__row">
    <mat-icon class="strength-indicator__row--icon">
      {{ !passwordControl.value || passwordControl.errors?.characters ? 'check_circle_outline' : 'check_circle' }}
    </mat-icon>
    Special characters (!&#64;#)
  </div>
  <div class="strength-indicator__row">
    <span class="strength-indicator__row--text">Strength:</span>
    <span class="{{getPasswordStrength()}}">{{passwordStrength}}</span>
  </div>
  <mat-progress-bar
    class="{{getPasswordStrength()}} strength-indicator__strength--progress"
    mode="determinate"
    [value]="passwordStrengthValue"
  ></mat-progress-bar>
</div>
