<div class="logout__formContainer">
  <div class="logout__image">
    <img
      class="logout__image--img"
      src="{{logoutImage}}"
    >
  </div>
  <div class="logout__title">
    {{title}}
  </div>
  <div class="logout__message">
    {{message}}
  </div>

  <a
    mat-raised-button
    class="logout__button kt__button__link"
    color="primary"
    routerLink="/login"
  >
    LOG BACK IN
  </a>

  <div class="logout__footer">Powered by Kahuna</div>
</div>
