import { Injectable } from '@angular/core';
import { BehaviorSubject, map, switchMap, timer, scan } from 'rxjs';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export const LOADING_IGNORE_TIME = 100;
export const MIN_LOADING_TIME = 200;

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {

  private readonly loaderCounter$ = new BehaviorSubject<number>(0);
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  constructor(
    private router: Router,
  ) {
    this.initValues();
    this.onNavigation();
  }

  public addLoader(): void {
    this.loaderCounter$.next(1);
  }

  public removeLoader(): void {
    this.loaderCounter$.next(-1);
  }

  private initValues(): void {

    this.loaderCounter$.pipe(
      scan((total: number, loader = 1 | -1) => Math.max(total + loader, 0), 0),
      map(count => count > 0),
      distinctUntilChanged(),
      debounceTime(LOADING_IGNORE_TIME),
      switchMap((isLoading) => timer(isLoading ? 0 : MIN_LOADING_TIME).pipe(map(() => isLoading))),
    ).subscribe(this._isLoading$);
  }

  private onNavigation(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.addLoader();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.removeLoader();
      }
    });
  }

}
