import { EmbeddedViewRef, Inject, Injectable, TemplateRef } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import {
  concatAll as rxConcatAll,
  map as rxMap,
  Observable,
  ObservableInput,
  Subject,
  Subscriber,
  Subscription,
  take as rxTake,
  takeUntil as rxTakeUntil,
} from 'rxjs';
import { DefaultToastComponent } from '@messaging/components';

export interface ToastMessage {
  action?: () => void;
  actionLabel?: string;
  componentData?: any;
  content: any;
  displayTime?: number;
  prefixIcon?: string;
  prefixUrl?: string;
  takeUntil?: Observable<ToastMessage | void>;
  theme?: 'normal' | 'warn' | 'error' | 'success' | 'neutral' | 'light' | 'dark';
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly msgQueue = new Subject<ToastMessage>();

  constructor(
    private snackBar: MatSnackBar,
    @Inject(MAT_SNACK_BAR_DEFAULT_OPTIONS)
    private snackBarDefaults: MatSnackBarConfig,
  ) {
    this.startQueue();
  }

  private generateConfig(source: ToastMessage): MatSnackBarConfig {
    const config: MatSnackBarConfig = {};
    if (source.displayTime && source.displayTime > 0) {
      config.duration = source.displayTime;
    }
    config.panelClass = this.setTheme(source.theme);
    config.data = source.componentData;
    return config;
  }

  private openToast(messageConfig: ToastMessage): MatSnackBarRef<any> {
    if (typeof messageConfig.content === 'string') {
      // The simple snackbar only matches our design system when an action is supplied.
      // Without the action, the default snackbar does not include the close button.
      return this.openToastFromString(messageConfig);
    }
    if (messageConfig.content instanceof TemplateRef) {
      return this.openToastFromTemplate(messageConfig);
    }
    return this.openToastFromComponent(messageConfig);
  }

  private openToastFromComponent(config: ToastMessage): MatSnackBarRef<any> {
    const ref = this.snackBar.openFromComponent(config.content, this.generateConfig(config));

    if (config.actionLabel && config.action) {
      this.startActionListener(ref, config.action);
    }

    return ref;
  }

  private openToastFromString(config: ToastMessage): MatSnackBarRef<TextOnlySnackBar> {
    const componentData = {
      content: config.content,
      prefixIcon: config.prefixIcon,
      prefixUrl: config.prefixUrl,
      actionLabel: config.actionLabel,
      hasAction: !!(config.action && config.actionLabel),
    };

    const toastConfig: ToastMessage = {
      ...config,
      content: DefaultToastComponent,
      componentData,
    };
    return this.openToastFromComponent(toastConfig);
  }

  private openToastFromTemplate(config: ToastMessage): MatSnackBarRef<EmbeddedViewRef<any>> {
    const ref: MatSnackBarRef<EmbeddedViewRef<any>> = this.snackBar.openFromTemplate(
      config.content,
      this.generateConfig(config),
    );

    if (config.action) {
      this.startActionListener(ref, config.action);
    }

    return ref;
  }

  public queueToastMessage(message: ToastMessage): void {
    this.msgQueue.next(message);
  }

  /**
   * Given the specified theme context, assigns global theme styles to the snackbar.
   * @param theme The theme context to set for the snackbar.
   * @returns An array of CSS class selectors to apply to the snackbar panel.
   */
  private setTheme(theme: string = 'normal'): string | string[] {
    const panelClass = this.snackBarDefaults.panelClass as string[];
    return panelClass.concat([`kt__snackbar__${theme}`]);
  }

  private startActionListener(ref: MatSnackBarRef<any>, action: () => void): Subscription {
    return ref.onAction().pipe(rxTakeUntil(ref.afterDismissed())).subscribe(action);
  }

  /**
   * Starts listening for messages to render to the snackbar.
   *
   * The `rxMap` operator does a lot of heavy lifting here between closures and a
   * custom observable; most of the logic here revolves around the `takeUntil` config
   * option.
   *
   * The primary goal is to open the message in the snackbar and mark the custom
   * observable complete when it's dismissed. The custom obserables are concatenated,
   * so when one completes, the next message is opened.
   *
   * If the message has a `takeUntil` stream in its configuration, we have to check
   * for two things:
   * 1. Does it emit BEFORE the message can be rendered, and
   * 2. If not, when does it emit AFTER the message is rendered.
   *
   * In either case, we want to dismiss the toast message (or not even open it) if
   * the stream emits. Additionally, if the stream emits a new message configuration,
   * we need to queue the emitted message.
   */
  private startQueue() {
    this.msgQueue
      .pipe(
        rxMap((nextMessage: ToastMessage): ObservableInput<any> => {
          let isExpired = false;
          if (nextMessage.takeUntil) {
            // WARNING! If `takeUntil` never emits, completes, or errors, this will
            // become a memory leak!
            nextMessage.takeUntil
              .pipe(rxTake(1))
              .subscribe((replacementMessage: ToastMessage | void) => {
                isExpired = true;
                if (replacementMessage) {
                  this.msgQueue.next(replacementMessage);
                }
              });
          }

          return new Observable((obs: Subscriber<any>) => {
            if (isExpired) {
              obs.complete();
              return () => {};
            }

            const toastRef = this.openToast(nextMessage);
            toastRef.afterDismissed().subscribe(() => {
              obs.complete();
            });

            if (nextMessage.takeUntil) {
              nextMessage.takeUntil
                .pipe(rxTake(1), rxTakeUntil(toastRef.afterDismissed()))
                .subscribe(() => {
                  toastRef.dismiss();
                });
            }

            return () => {
              toastRef.dismiss();
            };
          });
        }),
        rxConcatAll(),
      )
      .subscribe();
  }
}
