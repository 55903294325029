import { Component } from '@angular/core';

import { LoadingIndicatorService } from '@core/services';

@Component({
  selector: 'kws-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {

  constructor(
    public loadingIndicatorService: LoadingIndicatorService,
  ) { }
}
