import { Inject, Injectable, InjectionToken } from '@angular/core';
import { EmptyEnvironment, Environment, Environments } from '@core/constants';

export const getWindow = (): Window => {
  return window;
};

export const WINDOW = new InjectionToken<Window>('BrowserGlobalNamespace', {
  providedIn: 'root',
  factory: getWindow,
});

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  public readonly document: Document = this.window.document;
  private readonly location: Location = this.window.location;

  public get environment(): Environment {
    const hostName = this.getHostName().toLowerCase();
    return Environments.find((env) => {
      return hostName.startsWith(`${env.Key}.`) || hostName.startsWith(`${env.Key}-`);
    }) || EmptyEnvironment;
  }

  constructor(@Inject(WINDOW) public readonly window: Window & typeof globalThis) { }

  public setUrl(path: string, origin?: string): void {
    if (path.charAt(0) === '/' || path.charAt(0) === '\\') {
      path = path.slice(1);
    }

    if (origin) {
      // TODO(wayland): Build white list of allowed domains/origins. Throw error
      // if origin doesn't match any
      this.location.href = origin;
      return;
    } else {
      origin = this.location.origin;
    }

    this.location.href = `${origin}/${path}`;
  }

  public getHostName() {
    return this.location.hostname;
  }

  public encodeUri(uri: string): string {
    return this.window.encodeURIComponent(uri);
  }
}
