import { Component, Input, OnInit } from '@angular/core';

import { Environment } from '@core/constants';
import { WindowService } from '@core/services';

@Component({
  selector: 'kws-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string = '';

  public readonly currentEnvironment: Environment = this.windowService.environment;

  constructor(
    private windowService: WindowService,
  ) { }
}
