import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  map as rxMap,
  take as rxTake,
} from 'rxjs';

@Component({
  selector: 'kws-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  public readonly logoutImage = './assets/images/session-time-out.svg';
  public title = '';
  public message = '';

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(
      rxTake(1),
      rxMap(queryMaps => queryMaps.action),
    ).subscribe(logoutType => {
      if (!logoutType) {
        this.title = 'You have been successfully logged out.';
      } else {
        this.title = 'You session has expired.';
        this.message = `Don't worry we saved all your data.`;
      }
    });
  }
}
