import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';

import { LoadingIndicatorService } from '@core/services';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loadingIndicatorService: LoadingIndicatorService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingIndicatorService.addLoader();
    return next.handle(req).pipe(
      finalize(() => {
        this.loadingIndicatorService.removeLoader();
      }),
    );
  }
}
