export const PostRequestPasswordReset = () => {
  return {
    IsValid: true,
    Message: 'Successfully updated',
    Type: '',
    Exception: '',
    QueueRequestID: null,
  };
};

export const PostRequestPasswordResetFail = () => {
  return {
    IsValid: false,
    Message: '',
    Type: '',
    Exception: 'test',
    QueueRequestID: null,
  };
};
