import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { PasswordStrength, PasswordStrengthText } from '@login/constants';

@Component({
  selector: 'kws-strength-indicator',
  templateUrl: './strength-indicator.component.html',
  styleUrls: ['./strength-indicator.component.scss']
})
export class StrengthIndicatorComponent implements OnInit {
  @Input() passwordControl!: AbstractControl;

  public passwordStrength = '';
  public passwordStrengthValue = 0;

  constructor() { }

  ngOnInit(): void { }

  public getPasswordStrength(): string {
    if (this.passwordControl?.value) {
      const errors = [
        this.passwordControl?.errors?.minlength ? true : false,
        this.passwordControl?.errors?.upperCase || false,
        this.passwordControl?.errors?.lowerCase || false,
        this.passwordControl?.errors?.numbers || false,
        this.passwordControl?.errors?.characters || false,
      ];

      const count = errors.filter((error) => !error).length;

      if (count === 0) {
        this.passwordStrength = PasswordStrengthText.weak;
        this.passwordStrengthValue = 0;
        return PasswordStrength.weak;
      }
      if (count === 1) {
        this.passwordStrength = PasswordStrengthText.weak;
        this.passwordStrengthValue = 20;
        return PasswordStrength.weak;
      }
      if (count === 2) {
        this.passwordStrength = PasswordStrengthText.fair;
        this.passwordStrengthValue = 40;
        return PasswordStrength.fair;
      }
      if (count === 3) {
        this.passwordStrength = PasswordStrengthText.good;
        this.passwordStrengthValue = 60;
        return PasswordStrength.good;
      }
      if (count === 4) {
        this.passwordStrength = PasswordStrengthText.good;
        this.passwordStrengthValue = 80;
        return PasswordStrength.good;
      }
      if (count === 5) {
        this.passwordStrength = PasswordStrengthText.strong;
        this.passwordStrengthValue = 100;
        return PasswordStrength.strong;
      }
    }

    this.passwordStrength = PasswordStrengthText.weak;
    this.passwordStrengthValue = 0;
    return PasswordStrength.weak;
  }
}
