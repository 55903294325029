import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  combineLatest as rxCombineLatest,
  Subject,
  take as rxTake,
} from 'rxjs';

import { AnyObject, NavigationService } from '@core';
import { ToastService } from '@messaging/services';

import { WizardStage } from '@login/constants';
import { LoginService } from '@login/services';
import { BasicCompany, LoginType, SAMLSettingsBase, UserLogin } from '@login/types';
import { LoginErrorStateMatcher } from '@login/utils';

@Component({
  selector: 'kws-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  public readonly WizardStage = WizardStage;
  public hidePassword: boolean = true;

  public currentStage = this.WizardStage.Email;
  public rememberMe: boolean = false;
  public matcher = new LoginErrorStateMatcher();
  public userFormGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern('.+@.+\..+')]),
    password: new FormControl('', [Validators.required]),
    companyKey: new FormControl('', [Validators.required]),
  });

  companies: BasicCompany[] = [];
  submitted: boolean = false;

  private cookieName: string = 'rememberMe';

  get userFormControl() {
    return this.userFormGroup.controls;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private navigationService: NavigationService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.checkCookie(false);
    this.checkGenericURL();
  }

  public goBack() {
    switch (this.currentStage) {
      case this.WizardStage.Password:
        this.currentStage = this.WizardStage.Email;
        this.clearFormControl(this.userFormGroup.get('password') as FormControl);
        break;
      case this.WizardStage.Company:
        this.companies = [];
        this.currentStage = this.WizardStage.Password;
        this.clearFormControl(this.userFormGroup.get('companyKey') as FormControl);
        break;
    }
  }

  public beginLogin(): void {
    const emailControl = this.userFormGroup.controls['email'] as FormControl;

    if (emailControl.valid) {
      this.currentStage = this.WizardStage.Password;

      const rememberMeConst = this.checkCookie();
      if (this.rememberMe && rememberMeConst !== emailControl.value) {
        this.setCookie(emailControl.value);
      } else if (!this.rememberMe && rememberMeConst) {
        this.setCookie(emailControl.value, true)
      }

      if (this.userFormGroup.controls['password'].valid) {

        if (this.companies.length) {
          this.currentStage = this.WizardStage.Company;
          const companyError = !this.userFormGroup.controls['companyKey'].value;

          if (!companyError) {
            this.login();
          }
        } else {
          this.submitted = true;
          const postData = this.userFormGroup.value as UserLogin;
          this.loginService.getUserCompanies(postData).subscribe({
            next: (companyList) => {
              if (companyList.length > 1) {
                this.companies = companyList;
                this.currentStage = this.WizardStage.Company;
              } else if (companyList.length === 1) {
                this.userFormGroup.patchValue({ companyKey: companyList[0].Key });
                this.login();
              } else {
                this.toastService.queueToastMessage({
                  content: 'Oops! The Email or Password is incorrect. Please try again.',
                  theme: 'error'
                });
              }
            },
            error: (error) => {
              this.handleErrors(error);
            },
            complete: () => {
              this.submitted = false;
            }
          });
        }
      }
    }
  }

  private checkGenericURL() {
    this.loginService.getSAMLBaseSettings().subscribe({
      next: (settings: SAMLSettingsBase) => {
        if (settings.AuthenticationMode === LoginType.SAML) {
          this.loginService.beginSSO('maui/user');
        }
      },
      error: (e) => {
        this.toastService.queueToastMessage({
          content: 'Unable to retrieve required settings',
          theme: 'error'
        });
      },
    });
  }

  private login(): void {
    this.submitted = true;
    const postData = this.userFormGroup.value as UserLogin;
    rxCombineLatest([
      this.loginService.logIn(postData),
      this.activatedRoute.queryParams
    ]).pipe(
      rxTake(1),
    )
      .subscribe({
        next: ([loginResp, queryParamMap]: AnyObject[]) => {
          this.toastService.queueToastMessage({
            content: 'You have been successfully logged in.',
            theme: 'success'
          });
          this.navigationService.navigateToLandingPage(queryParamMap);
        },
        error: (error) => {
          this.handleErrors(error);
        },
        complete: () => {
          this.submitted = false;
        }
      });
  }

  private checkCookie(returnValue: boolean = true) {
    const storageVariable = localStorage.getItem(this.cookieName);

    if (storageVariable && storageVariable !== '') {
      if (!returnValue) {
        this.rememberMe = true;
        this.userFormGroup.patchValue({ email: storageVariable });
      }
      else {
        return storageVariable;
      }
    }
    return null;
  }

  private setCookie(email: string, expire: boolean = false) {
    if (expire) {
      localStorage.removeItem(this.cookieName);
      return;
    }
    localStorage.setItem(this.cookieName, email);
  }

  private clearFormControl(control: FormControl) {
    control.setErrors(null);
    control.setValue('');
    control.updateValueAndValidity();
  }

  private handleErrors(error: any) {
    if (error instanceof HttpResponse || error instanceof HttpErrorResponse) {
      this.toastService.queueToastMessage({
        content: error.statusText,
        theme: 'error'
      });
    } else if (error instanceof Error) {
      this.toastService.queueToastMessage({
        content: error.message,
        theme: 'error'
      });
    } else {
      this.toastService.queueToastMessage({
        content: error,
        theme: 'error'
      });
    }
  }
}
