import { HttpBackend, HttpClient } from '@angular/common/http';
import { inject, Injectable, InjectionToken } from '@angular/core';

interface ApiConfig {
  v1ApiUrl: string;
}


/**
 * Retrieves and provides the base url for each available server API. The server runtime environment dictates these base
 * URL values, so they cannot be set at compile time. Because all other API calls depend on these URLs, this service
 * must be invoked with the APP_INITIALIZER provider.
 *
 * The purpose of this service is to initialize API endpoint access and should not be injected anywhere in the app
 * except as an APP_INITIALIZER provider. Instead, each API base url should be provided in root as a separate injection
 * token for use in services that require server API access.
 */
 @Injectable({
  providedIn: 'root'
})
export class ApiConfigService {
  private apiConfig: ApiConfig = { v1ApiUrl: '' };

  public get v1BaseUrl(): string {
    return this.apiConfig.v1ApiUrl;
  }

  constructor(private http: HttpBackend) {}

  /**
   * Retrieves all known API base urls.
   */
  public getBaseUrlsForApis(): () => Promise<ApiConfig> {
    return () => {
      return new Promise<ApiConfig>((resolve, reject) => {
        // If we inject the HttpClient directly, it will force the creation of other dependencies (like interceptors)
        // that aren't part of the APP_INITIALIZER phase. This can cause injection tokens for these base urls to be
        // defined before the request to fetch the urls responds (thus, all base URLs will be undefined). To get around
        // this, we create our own instance of the HttpClient service, which doesn't share the rest of the app's
        // normal dependencies for the service.
        new HttpClient(this.http).get<ApiConfig>('api/env/apiurl').subscribe(
          (config) => {
            this.apiConfig = config;
            resolve(config);
          },
          // TODO(wayland): What should we do if we can't retrieve the URLs?!
          (error) => {
            reject(error);
          },
        );
      })
    };
  }
}



/** ** API BASE URL INJECTION TOKENS ** **/
// The following injection tokens should be consumed by services requiring server API access.

/**
 * Original server API implementation.
 */
export const V1_BASE_URL = new InjectionToken<string>('V1_BASE_URL', {
  providedIn: 'root',
  factory: () => inject(ApiConfigService).v1BaseUrl,
});
