<div class="logo">
  <button
    *ngIf="showBack"
    class="logo__backButton"
    type="button"
    (click)="buttonClick($event)"
  >
    <mat-icon class="logo__backButton--icon">arrow_back</mat-icon>
  </button>
  <img
    class="logo__image"
    src="{{logoToShow}}"
    (error)="$any($event.target).src = defaultLogo"
  />
</div>
