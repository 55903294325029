import { Injectable } from '@angular/core';
import { WindowService } from '@core/services/window';
import { AnyObject, ConstStringMap } from '@core/types';

enum KahunaEnv {
  Core = 0,
  Ui2,
  None,
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public static readonly KahunaEnv = KahunaEnv;

  constructor(
    private windowService: WindowService,
  ) { }

  public navigateToLandingPage(queryParams: ConstStringMap = {}) {
    const urlPath = Object.keys(queryParams).reduce((url, paramName, index) => {
      const paramKey = this.windowService.encodeUri(paramName);
      const paramValue = this.windowService.encodeUri(queryParams[paramName]);

      return `${url}${index === 0 ? '?' : '&'}${paramKey}=${paramValue}`;
    }, 'Home/Landing');

    let origin = undefined;

    const scheme = new RegExp('://'); // is a scheme present?

    if (queryParams?.return && scheme.test(queryParams?.return)) {
      origin = queryParams.return;
    }

    this.windowService.setUrl(urlPath, origin);
  }
}
