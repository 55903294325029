import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  take as rxTake,
} from 'rxjs';

import { RetrieveByKey } from '@login/interfaces';
import { LoginService } from '@login/services';

@Component({
  selector: 'kws-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() showBack: boolean = false;
  @Output() buttonClicked = new EventEmitter();

  public logoToShow: any = './assets/images/logo.svg';
  public readonly defaultLogo: any = './assets/images/logo.svg';

  constructor(
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.getCompanyLogo();
  }

  public buttonClick(event: Event) {
    event.preventDefault();
    this.buttonClicked.emit();
  }

  private getCompanyLogo() {
    this.loginService.getCompanyLogo().pipe(rxTake(1))
      .subscribe((response: RetrieveByKey) => {
        if (response.UseBlobStorage && response.LogoBlobStoragePathDisplay && response.LogoBlobStoragePathDisplay !== '') {
          this.logoToShow = response.LogoBlobStoragePathDisplay;
        } else if (response.LogoPath && response.LogoPath !== '') {
          this.logoToShow = response.LogoPath;
        }
      });
  }
}
