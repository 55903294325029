export enum EnvironmentKeys {
  Local = 'boo',
  Alpha = 'alpha',
  Beta = 'beta',
  BetaEun = 'betaeun',
  Sandbox = 'sandbox',
  Demo = 'demo',
  Dev = 'dev',
  Development = 'development',
  Staging = 'staging',
  Infra = 'infra',
}
