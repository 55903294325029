import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import {
  timer as rxTimer,
  take as rxTake,
} from 'rxjs';

import { ToastService } from '@messaging/services';
import { LoginService } from '@login/services';
import { RequestPasswordReset } from '@login/types';
import { LoginErrorStateMatcher } from '@login/utils';

@Component({
  selector: 'kws-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
})
export class RequestPasswordComponent implements OnInit {

  public submitted: boolean = false;
  public matcher = new LoginErrorStateMatcher();
  public userFormGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern('.+@.+\..+')]),
    companyKey: new FormControl(this.loginService.getCompanyKey()),
  });

  get userFormControl() {
    return this.userFormGroup.controls;
  }

  constructor(
    private loginService: LoginService,
    protected router: Router,
    private toastService: ToastService,
  ) { }

  ngOnInit() { }

  public requestPassword(): void {
    if (this.userFormGroup.controls['email'].valid) {
      this.submitted = true;
      const postData = this.userFormGroup.value as RequestPasswordReset;

      this.loginService.requestPasswordReset(postData).subscribe({
        next: (response) => {
          if (response.IsValid) {
            this.toastService.queueToastMessage({
              content: 'Your request for a password reset has been sent, you should receive it shortly.',
              theme: 'success'
            });
            rxTimer(600).subscribe(() => {
              this.router.navigate(['/login']);
            });
          } else {
            this.toastService.queueToastMessage({
              content: response.Message != '' ? response.Message : 'Something went wrong, please try again.',
              theme: 'error'
            });
          }
        },
        error: (error) => {
          if (error instanceof HttpResponse || error instanceof HttpErrorResponse) {
            this.toastService.queueToastMessage({
              content: error.statusText,
              theme: 'error'
            });
          } else if (error instanceof Error) {
            this.toastService.queueToastMessage({
              content: error.message,
              theme: 'error'
            });
          } else {
            this.toastService.queueToastMessage({
              content: error,
              theme: 'error'
            });
          }
        },
        complete: () => {
          this.submitted = false;
        }
      });
    }
  }

  public goBack() {
    this.router.navigate(['/login']);
  }
}
