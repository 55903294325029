<div class="DefaultToast__Root">
  <mat-icon *ngIf="prefixIcon" class="DefaultToast__Bookend DefaultToast__Prefix">{{ prefixIcon }}</mat-icon>
  <img *ngIf="prefixUrl" class="DefaultToast__Bookend DefaultToast__Prefix" [src]="prefixUrl" />
  <span class="DefaultToast__Content">{{ content }}</span>
  <button
    *ngIf="hasAction; else closeButton"
    class="DefaultToast__Bookend DefaultToast__Suffix"
    mat-button
    (click)="action()"
  >{{ actionLabel }}</button>
  <ng-template #closeButton>
    <mat-icon
      class="DefaultToast__Bookend DefaultToast__Suffix"
      (click)="dismiss()"
    >close</mat-icon>
  </ng-template>
</div>