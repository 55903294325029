import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';

import { CoreModule } from '@core';

import {
  LoginComponent,
  LogoutComponent,
  RequestPasswordComponent,
  ResetPasswordComponent,
} from '@login/components';

@NgModule({
  declarations: [
    LoginComponent,
    RequestPasswordComponent,
    LogoutComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule
  ]
})
export class LoginModule { }
