import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AnyObject, EnvironmentPrefixes, ExcludeDomains, V1_BASE_URL, WindowService } from '@core';
import { BasicCompany, SAMLSettingsBase, UserLogin, PasswordReset, RequestPasswordReset } from '@login/types';
import { PostAPIResponse, RetrieveByKey } from '@login/interfaces';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly baseUrl = `${this.apiBaseUrl}/account`;

  constructor(
    // TODO(wayland): We should not inject HttpClient directly into services. If we ever want to
    // overload its methods, we would need to do so everywhere it's injected. Maintainability issue.
    protected http: HttpClient,
    private windowService: WindowService,
    @Inject(V1_BASE_URL) protected apiBaseUrl: string,
  ) { }

  public beginSSO(returnPath: string) {
    // TODO(wayland): Add window service or injection token to avoid global reference.
    const returnUrl = encodeURIComponent(window.location.host + returnPath);
    window.location.href = `${this.baseUrl}/StartSSO?returnurl=${returnUrl}`;
  }

  public getSAMLBaseSettings(): Observable<SAMLSettingsBase> {
    return this.http.get<SAMLSettingsBase>(
      `${this.baseUrl}/GetSamlBaseSettings`,
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false,
      },
    );
  }

  public getUserCompanies(user: UserLogin): Observable<BasicCompany[]> {
    const body = { Email: user.email, Password: user.password };

    return this.http.post<BasicCompany[]>(
      `${this.baseUrl}/RetrieveUserCompanies`,
      body,
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false,
      },
    );
  }

  public logIn(credentials: UserLogin): Observable<HttpResponse<AnyObject>> {
    return this.http.post<AnyObject>(
      `${this.baseUrl}/login`,
      credentials,
      {
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      },
    );
  }

  public requestPasswordReset(credentials: RequestPasswordReset): Observable<PostAPIResponse> {
    return this.http.post<PostAPIResponse>(
      `${this.baseUrl}/RequestPasswordReset`,
      credentials,
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false,
      },
    );
  }

  public passwordReset(credentials: PasswordReset): Observable<PostAPIResponse> {
    return this.http.post<PostAPIResponse>(
      `${this.baseUrl}/PasswordReset`,
      credentials,
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false,
      },
    );
  }

  public logOut(): Observable<HttpResponse<AnyObject>> {
    return this.http.post<AnyObject>(
      `${this.baseUrl}/logout`,
      {},
      {
        observe: 'response',
        responseType: 'json',
        withCredentials: true,
      },
    );
  }

  public getCompanyLogo(): Observable<RetrieveByKey> {
    const companyKey = this.getCompanyKey();
    const postData = {
      "Text": companyKey,
    };
    return this.http.post<RetrieveByKey>(
      `${this.apiBaseUrl}/Company/RetrieveByKey`,
      postData,
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: false,
      },
    );
  }

  public getCompanyKey() {
    const subDomain = this.windowService.getHostName().split('.')[0];

    if (!subDomain || ExcludeDomains.includes(subDomain)) {
      return '';
    }

    const env = EnvironmentPrefixes.find((prefix) => subDomain.startsWith(`${prefix}-`));
    return env ? subDomain.substring(env.length + 1) : '';
  }
}
