<mat-card class="reset-password__formContainer">
  <form
    aria-labelledby="title"
    (submit)="resetPassword()"
    [formGroup]="userFormGroup"
  >
    <mat-card-content class="reset-password__cardContent">
      <kws-logo></kws-logo>
      <kws-header [title]="headerTitle"></kws-header>

      <mat-form-field
        appearance="fill"
        class="reset-password__formField"
        color="primary"
      >
        <mat-label>Password</mat-label>
        <input
          ngDefaultControl
          matInput
          autofocus
          formControlName="password"
          name="password"
          type="password"
          [errorStateMatcher]="matcher"
          [attr.aria-invalid]="passwordControl.invalid && passwordControl.touched ? true : null"
          (focus)="passwordFocus()"
          (blur)="passwordBlur()"
          tabindex="1"
        >
        <mat-hint>Enter your new password</mat-hint>
        <mat-error *ngIf="passwordControl.errors?.required">Password is required.</mat-error>
      </mat-form-field>
      <ng-container *ngIf="showStrengthIndicator">
        <div class="reset-password__strength-indicator">
          <kws-strength-indicator [passwordControl]="passwordControl"></kws-strength-indicator>
        </div>
      </ng-container>
      <mat-form-field
        appearance="fill"
        class="reset-password__formField"
        color="primary"
      >
        <mat-label>Confirm Password</mat-label>
        <input
          ngDefaultControl
          matInput
          formControlName="confirmPassword"
          name="confirmPassword"
          type="password"
          [errorStateMatcher]="matcher"
          [attr.aria-invalid]="passwordConfirmControl.invalid && passwordConfirmControl.touched ? true : null"
          tabindex="2"
        >
        <mat-hint>Retype your password to confirm it</mat-hint>
        <mat-error *ngIf="passwordConfirmControl.errors?.required">Password is required.</mat-error>
        <mat-error *ngIf="passwordConfirmControl.errors?.matching">Passwords do not match.</mat-error>
      </mat-form-field>

      <button
        mat-raised-button
        class="reset-password__formSubmit"
        color="primary"
        [disabled]="!userFormGroup.valid || submitted"
        type="submit"
      >
        RESET PASSWORD
      </button>

      <div class="reset-password__footer">Powered by Kahuna</div>

    </mat-card-content>
  </form>
</mat-card>
