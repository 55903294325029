<mat-card class="request-password__formContainer">
  <form
    aria-labelledby="title"
    (submit)="requestPassword()"
    [formGroup]="userFormGroup"
  >

    <mat-card-content class="request-password__cardContent">
      <kws-logo
        [showBack]="true"
        (buttonClicked)="goBack()"
      ></kws-logo>

      <kws-header [title]="'Password Request'"></kws-header>

      <div class="request-password__content">
        Please enter your email address and click the "Request Password Reset" button below, we will send you an email with a link to reset your password. Please click on the link and it will take you the password reset screen.
      </div>

      <mat-form-field
        appearance="fill"
        class="request-password__formField"
        color="primary"
      >
        <mat-label>Email</mat-label>
        <input
          ngDefaultControl
          matInput
          autofocus
          formControlName="email"
          name="email"
          placeholder="Enter your email address..."
          type="email"
          [errorStateMatcher]="matcher"
          [attr.aria-invalid]="userFormControl.email.invalid && userFormControl.email.touched ? true : null"
        >
        <mat-error>
          <span *ngIf="userFormControl.email.errors?.required">Email address is required.</span>
          <span *ngIf="userFormControl.email.errors?.pattern"
          >Invalid email format. Please enter a valid email address.
          </span>
        </mat-error>
      </mat-form-field>
      <div class="request-password__moreInfo">The email address that you login with</div>
      <button
        mat-raised-button
        class="request-password__formSubmit"
        color="primary"
        [disabled]="!userFormControl.email.valid || submitted"
        type="submit"
      >
        REQUEST PASSWORD RESET
      </button>

      <div class="request-password__footer">Powered by Kahuna</div>

    </mat-card-content>
  </form>
</mat-card>
