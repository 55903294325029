import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import {
  catchError,
  Observable,
  map as rxMap,
  of as rxOf,
  take as rxTake,
  tap as rxTap,
} from 'rxjs';

import { V1_BASE_URL, WindowService } from '@core';
import { SAMLSettingsBase } from '@login/types';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard  {
  private readonly baseUrl = `${this.apiBaseUrl}/Account`;
  private readonly logout: Observable<SAMLSettingsBase> = this.initLogout();

  constructor(
    protected http: HttpClient,
    private windowService: WindowService,
    @Inject(V1_BASE_URL) protected apiBaseUrl: string,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.logout.pipe(
      rxTake(1),
      rxTap(() => {
        // Clear any cookies for the site by invalidating them via expiration date.
        this.windowService.document.cookie = 'Auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }),
      rxMap((logoutResponse: SAMLSettingsBase) => {
        if (!logoutResponse || logoutResponse.AuthenticationMode === 'basic' || !logoutResponse.LogoffUrl) {
          return true;
        }

        this.windowService.setUrl(logoutResponse.LogoffUrl);
        return false;
      }),
      catchError(err => rxOf(true)),
    );
  }

  private initLogout() {
    return this.http.post<SAMLSettingsBase>(
      `${this.baseUrl}/Logout`,
      {},
      {
        observe: 'body',
        responseType: 'json',
        withCredentials: true,
      },
    );
  }
}

export const canLogout: CanActivateFn = function canLogoutRouteGuard(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean> {
  return inject(LogoutGuard).canActivate(route, state);
}