import { Component, Inject, Input } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ToastMessage } from '@messaging/services';

interface DefaultToastConfig extends ToastMessage {
  hasAction: boolean;
}

@Component({
  selector: 'kws-default-toast',
  templateUrl: './default-toast.component.html',
  styleUrls: ['./default-toast.component.scss'],
})
export class DefaultToastComponent implements ToastMessage {
  public readonly hasAction = (this.data || {}).hasAction;
  @Input() public content: string = (this.data || {}).content;
  @Input() public actionLabel: string | undefined = (this.data || {}).actionLabel;
  @Input() public prefixIcon: string | undefined = (this.data || {}).prefixIcon;
  @Input() public prefixUrl: string | undefined = (this.data || {}).prefixUrl;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: DefaultToastConfig,
    private snackBar: MatSnackBarRef<DefaultToastComponent>,
  ) {}

  dismiss() {
    this.snackBar.dismiss();
  }

  action() {
    this.snackBar.dismissWithAction();
  }
}
