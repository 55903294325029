import { StringMap } from '@login/interfaces';

export const PasswordStrength: StringMap = {
  weak: 'strength-indicator__strength--weak',
  fair: 'strength-indicator__strength--fair',
  good: 'strength-indicator__strength--good',
  strong: 'strength-indicator__strength--strong',
}

export const PasswordStrengthText: StringMap = {
  weak: 'Weak',
  fair: 'Fair',
  good: 'Good',
  strong: 'Strong',
};
