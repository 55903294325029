<mat-card class="login__formContainer">
  <form
    aria-labelledby="title"
    (submit)="beginLogin()"
    [formGroup]="userFormGroup"
  >
    <mat-card-content class="login__card-content">

      <kws-logo
        [showBack]="currentStage !== WizardStage.Email"
        (buttonClicked)="goBack()"
      ></kws-logo>

      <ng-container [ngSwitch]="currentStage">
        <ng-container *ngSwitchCase="WizardStage.Email">
          <kws-header [title]="'Sign in to continue'"></kws-header>
          <mat-form-field
            appearance="fill"
            class="login__formField"
            color="primary"
          >
            <mat-label>Email</mat-label>
            <input
              ngDefaultControl
              matInput
              autofocus
              formControlName="email"
              name="email"
              placeholder="Enter your email address..."
              type="email"
              [errorStateMatcher]="matcher"
              [attr.aria-invalid]="userFormControl.email.invalid && userFormControl.email.touched ? true : null"
            >
            <mat-error>
              <span *ngIf="userFormControl.email.errors?.required">Email address is required.</span>
              <span *ngIf="userFormControl.email.errors?.pattern"
              >Invalid email format. Please enter a valid email address.</span>
            </mat-error>
          </mat-form-field>
          <ng-container *ngTemplateOutlet="extraActions; context: {}"></ng-container>
          <button
            mat-raised-button
            class="login__formSubmit"
            color="primary"
            [disabled]="!userFormControl.email.valid || submitted"
            type="submit"
          >
            Next
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="WizardStage.Password">
          <kws-header [title]="'Verify your password'"></kws-header>
          <mat-form-field
            appearance="fill"
            class="login__formField"
            color="primary"
          >
            <mat-label>Password</mat-label>
            <input
              ngDefaultControl
              matInput
              autofocus
              formControlName="password"
              name="password"
              placeholder="Enter your password..."
              [type]="hidePassword ? 'password' : 'text'"
              [errorStateMatcher]="matcher"
              [attr.aria-invalid]="userFormControl.password.invalid && userFormControl.password.touched ? true : null"
            >
            <mat-icon
              matSuffix
              (click)="hidePassword = !hidePassword"
            >{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="userFormControl.password.errors?.required">Password is required.</mat-error>
          </mat-form-field>
          <ng-container *ngTemplateOutlet="extraActions; context: {}"></ng-container>
          <button
            mat-raised-button
            class="login__formSubmit"
            color="primary"
            [disabled]="!userFormControl.password.valid || submitted"
            type="submit"
          >
            Log In
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="WizardStage.Company">
          <kws-header [title]="'Select your company'"></kws-header>
          <mat-form-field
            appearance="fill"
            class="login__formField"
            color="primary"
          >
            <mat-label>Company</mat-label>
            <mat-select
              ngDefaultControl
              autofocus
              name="companyKey"
              formControlName="companyKey"
              [errorStateMatcher]="matcher"
              placeholder="Select your company"
            >
              <mat-option
                *ngFor="let c of companies"
                [value]="c.Key"
              >
                {{ c.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="userFormControl.companyKey.errors?.required">Company is required.</mat-error>
          </mat-form-field>
          <div class="login__actions--separator login__actions"></div>
          <button
            mat-raised-button
            class="login__formSubmit"
            color="primary"
            [disabled]="!userFormGroup.valid || submitted"
            type="submit"
          >
            Continue
          </button>
        </ng-container>

      </ng-container>
      <div class="login__footer">Powered by Kahuna</div>
    </mat-card-content>
  </form>
</mat-card>

<ng-template
  #extraActions
  let-showReset="showReset=true"
>
  <div class="login__row login__actions">
    <div class="login__column">
      <mat-checkbox
        indeterminate="false"
        name="rememberMe"
        [(ngModel)]="rememberMe"
        class="float-end"
        ngDefaultControl
      >
        Remember me
      </mat-checkbox>
    </div>
    <div
      *ngIf="showReset"
      class="login__column login__column--right"
    >
      <a
        mat-button
        class="kt__button__link float-end"
        color="primary"
        routerLink="/request-password"
      >
        Forgot Password?
      </a>
    </div>
  </div>
</ng-template>
