export interface RetrieveByKey {
  CompanyID: number;
  Name: string;
  Key: string;
  LogoPath: string;
  LogoBlobStoragePath: string;
  UseBlobStorage: boolean;
  LogoBlobStoragePathDisplay: string;
  Environment: number;
  Url: string;
  SortOrder: number;
  IsDeleted: boolean;
  IsActive: boolean;
  IsSystemLocked: boolean;
  Paging?: {
    PageNumber: number;
    ItemsPerPage: number;
  };
  Security?: {
    Key: string;
    Action: number;
  };
  IsModified: boolean;
  CRUD: number;
}
