import { EnvironmentKeys } from './environment-keys.enum';

export interface Environment {
  Key: string;
  Badge: string;
}

export const EmptyEnvironment: Readonly<Environment> = {
  Key: '',
  Badge: '',
};

export const Environments: ReadonlyArray<Environment> = [
  {
    Key: EnvironmentKeys.Local,
    Badge: 'Local Dev',
  },
  {
    Key: EnvironmentKeys.Alpha,
    Badge: 'Alpha',
  },
  {
    Key: EnvironmentKeys.Beta,
    Badge: 'Beta',
  },
  {
    Key: EnvironmentKeys.BetaEun,
    Badge: 'Beta EUN',
  },
  {
    Key: EnvironmentKeys.Sandbox,
    Badge: 'Sandbox',
  },
  {
    Key: EnvironmentKeys.Demo,
    Badge: 'Demo',
  },
  {
    Key: EnvironmentKeys.Dev,
    Badge: 'Development',
  },
  {
    Key: EnvironmentKeys.Development,
    Badge: 'Development',
  },
  {
    Key: EnvironmentKeys.Staging,
    Badge: 'Staging',
  },
  {
    Key: EnvironmentKeys.Infra,
    Badge: 'Infra',
  },
] as const;

export const EnvironmentPrefixes: string[] = [
  'alpha',
  'beta',
  'local',
  'sandbox',
  'demo',
  'development',
  'betaeun',
  'betaeun2',
  'staging',
  'eun',
];

// For any of the below sub-domains, we don't want to perform certain functions
// of the auth service, like requesting password resets using company keys.
export const ExcludeDomains = [
  'aks',
  'alpha',
  'beta',
  'betaeun',
  'dev',
  'eun-aks',
  'sandbox',
  'staging',
];
