import { APP_INITIALIZER, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';

import { MaterialModule } from '../material/material.module';

import {
  HeaderComponent,
  LogoComponent,
  ProgressBarComponent,
  StrengthIndicatorComponent,
} from '@core/components';
import { httpInterceptorProviders } from '@core/interceptors';
import { ApiConfigService } from '@core/services';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  declarations: [
    HeaderComponent,
    LogoComponent,
    ProgressBarComponent,
    StrengthIndicatorComponent,
  ],
  exports: [
    HeaderComponent,
    LogoComponent,
    ProgressBarComponent,
    StrengthIndicatorComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (apiConfig: ApiConfigService) => apiConfig.getBaseUrlsForApis(),
      deps: [ApiConfigService],
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/auth/',
    },
    httpInterceptorProviders,
  ],
})
export class CoreModule { }
